var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "default-layout",
    { staticClass: "h-full" },
    [
      _c("div", { staticClass: "flex h-full flex-1 px-2 pt-2" }, [
        _c(
          "div",
          { staticClass: "h-full flex-1 flex-shrink-0 bg-gray-100" },
          [
            _c("ClinicMap", {
              staticClass: "w-full",
              attrs: { "append-ratings": true, country: _vm.countryId },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "ml-2 w-1/4" }, [
          _c(
            "div",
            {
              staticClass:
                "flex h-full max-h-full flex-col space-y-4 overflow-auto",
            },
            [
              _c(
                "card",
                { staticClass: "bg-gray-100" },
                [
                  _c("clinic-listing-validation-form", {
                    attrs: {
                      "insurance-company": _vm.insuranceCompany,
                      "country-id": _vm.countryId,
                    },
                    on: {
                      "selected-ic": function ($event) {
                        _vm.insuranceCompany = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _c("clinic-listing-available-slots", {
                attrs: { country: _vm.country },
              }),
              _c(
                "base-button",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      return (_vm.showBookingModal = true)
                    },
                  },
                },
                [_vm._v("Book appointment ")]
              ),
              _vm.insuranceCompany
                ? _c(
                    "card",
                    {
                      key: _vm.insuranceCompany.id,
                      staticClass: "bg-gray-200",
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "mb-4 border-b pb-3 font-black" },
                        [_vm._v("Clinics")]
                      ),
                      _c("clinic-listing-select-form", {
                        attrs: {
                          "insurance-company": _vm.insuranceCompany,
                          clinics: _vm.clinicsInView,
                        },
                        on: {
                          "reset-insurance-select": function ($event) {
                            _vm.insuranceCompany = null
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _vm.showBookingModal
        ? _c("RemoteBookingController", {
            attrs: { "account-creation": true },
            on: {
              close: function ($event) {
                _vm.showBookingModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }