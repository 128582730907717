<template>
  <div class="flex flex-col space-y-6">
    <div class="flex flex-col space-y-2">
      <ul class="flex flex-col space-y-2">
        <ClinicListingClinicRow
          v-for="(clinic) in topRatedClinics"
          :key="clinic.id"
          :clinic="clinic"
          :insurance-company="insuranceCompany"
          @add-clinic="addClinic"
        />

        <li v-if="!topRatedClinics.length">
          <p>Move around or pan out to find more clinics.</p>
        </li>
      </ul>
      <div class="flex justify-end">
        <text-button
          v-if="bottomRatedAmount > 0"
          size="sm"
          @click="showFullList = true"
        >
          View full list <span
            class="ml-1"
          >({{ notSelectedClinics.length }})</span>
        </text-button>
      </div>
    </div>

    <div
      v-show="selectedClinics.length"
      id="recommendations"
      class="flex flex-col"
    >
      <p class="pb-3 border-b font-black">
        Recommendations
      </p>
      <ul class="mt-2">
        <li
          v-for="(clinic) in selectedClinics"
          :key="clinic.id"
          class="flex justify-between py-2 "
        >
          <div class="flex space-x-2 items-center">
            <font-awesome-icon
              icon="check-circle"
              class="text-green-600 text-xs"
            />
            <p>{{ clinic.name }}</p>
          </div>
          <button @click="removeClinic(clinic)">
            <font-awesome-icon
              icon="minus"
              class="text-gray-600 hover:text-gray-700 "
            />
          </button>
        </li>
      </ul>
      <base-button
        class="mt-4"
        color="dark"
        @click="showSendModal = true"
      >
        <div class="inline-flex items-center space-x-2">
          <span>Send recommendations</span>
        </div>
      </base-button>
    </div>

    <base-modal
      v-if="showFullList"
      v-show="showFullList"
      id="fullList"
      @close="showFullList = false"
    >
      <div
        class="p-4overflow-hidden"
        style="height: 65vh;"
      >
        <div class=" flex flex-col space-y-4 pr-4">
          <base-input
            v-model="searchStr"
            type="search"
            name="searchStr"
            placholder="Search"
          />
          <div class="flex space-x-4 text-xs font-semibold px-3">
            <div class="w-2/3">
              Clinic
            </div>
            <div class="flex-1 text-left">
              Price
            </div>
            <div class="flex-1 text-left">
              Quality
            </div>
            <div class="flex-1 text-left">
              Insurance
            </div>
            <div class="flex-1 text-left">
              Rating
            </div>
            <div class="flex-1 text-left" />
          </div>
          <ul class="flex flex-col space-y-2 overflow-auto">
            <ClinicListingClinicRow
              v-for="(clinic) in searchResult"
              :key="clinic.id"
              :clinic="clinic"
              :insurance-company="insuranceCompany"
              :show-all-ratings="true"
              @add-clinic="addClinic"
            />
          </ul>
        </div>
      </div>
    </base-modal>
    <clinic-listing-send-form
      v-if="insuranceCompany && showSendModal"
      :show="showSendModal"
      :insurance-company="insuranceCompany"
      :clinics="selectedClinics"
      @close="showSendModal = false"
      @sent="clearSelect"
    />
  </div>
</template>

<script>
import ClinicListingSendForm from '@/components/clinic-listing/ClinicListingSendForm.vue';
import ClinicListingClinicRow from '@/components/clinic-listing/ClinicListingClinicRow.vue';

export default {
  components: {
    ClinicListingSendForm,
    ClinicListingClinicRow,
  },
  props: {
    clinics: {
      type: Array,
      default: () => [],
    },
    insuranceCompany: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showSendModal: false,
      showFullList: false,
      selectedClinics: [],
      searchStr: '',
    };
  },
  computed: {
    clinicsOrdered() {
      const sortedByRating = this.clinics
        .filter(clinic => clinic.ratings && clinic.ratings.length)
        .filter(clinic =>
          clinic.ratings.find(
            rating => rating.insurance_id === this.insuranceCompany.id
          )
        )
        .map(clinic => {
          const currentRating = clinic.ratings.find(
            rating => rating.insurance_id === this.insuranceCompany.id
          );
          const aggRating = (
            (currentRating.rating +
              clinic.rating_price +
              clinic.rating_quality) /
            3
          ).toFixed(0);
          return {
            currentRating,
            aggRating,
            ...clinic,
          };
        })
        .sort((a, b) => b.aggRating - a.aggRating);

      return sortedByRating;
    },
    notSelectedClinics() {
      const selectedClinicIds = this.selectedClinics.map(clinic => clinic.id);

      return this.clinicsOrdered.filter(clinic => {
        return !selectedClinicIds.includes(clinic.id);
      });
    },
    searchResult() {
      if (!this.searchStr) {
        return this.notSelectedClinics;
      }
      return this.notSelectedClinics.filter(clinic =>
        clinic.name.toLowerCase().includes(this.searchStr.toLowerCase())
      );
    },
    topRatedClinics() {
      return this.notSelectedClinics.slice(0, 5);
    },
    bottomRatedAmount() {
      return this.clinics.length - 5;
    },
  },
  methods: {
    clearSelect() {
      this.selectedClinics = [];
      this.showSendModal = false;
      this.$emit('reset-insurance-select');
    },
    addClinic(clinic) {
      this.selectedClinics.push(clinic);
    },
    removeClinic(clinic) {
      this.selectedClinics = this.selectedClinics.filter(
        clinicInArray => clinicInArray.id !== clinic.id
      );
    },
  },
};
</script>
