<template>
  <default-layout class="h-full">
    <div class="flex h-full flex-1 px-2 pt-2">
      <div class="h-full flex-1 flex-shrink-0 bg-gray-100">
        <ClinicMap :append-ratings="true" class="w-full" :country="countryId" />
      </div>
      <div class="ml-2 w-1/4">
        <div class="flex h-full max-h-full flex-col space-y-4 overflow-auto">
          <card class="bg-gray-100">
            <clinic-listing-validation-form
              :insurance-company="insuranceCompany"
              :country-id="countryId"
              @selected-ic="insuranceCompany = $event"
            />
          </card>
          <clinic-listing-available-slots :country="country" />
          <base-button
            color="primary"
            @click="$event => (showBookingModal = true)"
            >Book appointment
          </base-button>
          <card
            v-if="insuranceCompany"
            :key="insuranceCompany.id"
            class="bg-gray-200"
          >
            <p class="mb-4 border-b pb-3 font-black">Clinics</p>
            <clinic-listing-select-form
              :insurance-company="insuranceCompany"
              :clinics="clinicsInView"
              @reset-insurance-select="insuranceCompany = null"
            />
          </card>
        </div>
      </div>
    </div>
    <RemoteBookingController
      v-if="showBookingModal"
      :account-creation="true"
      @close="showBookingModal = false"
    />
  </default-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ClinicMap from '@/components/clinic-map/ClinicMap.vue';
import ClinicListingSelectForm from '@/components/clinic-listing/ClinicListingSelectForm.vue';
import ClinicListingValidationForm from '@/components/clinic-listing/ClinicListingValidationForm.vue';
import ClinicListingAvailableSlots from '@/components/clinic-listing/ClinicListingAvailableSlots.vue';
import { SV_COUNTRY_ID } from '@/config/countries';
import RemoteBookingController from '@/components/remote-booking/RemoteBookingController.vue';

export default {
  name: 'MapContainer',
  components: {
    ClinicMap,
    ClinicListingSelectForm,
    ClinicListingValidationForm,
    ClinicListingAvailableSlots,
    RemoteBookingController,
  },
  data() {
    return {
      insuranceCompany: null,
      menuIsOpen: false,
      showBookingModal: false,
      country: {
        id: SV_COUNTRY_ID,
        timeZone: 'Europe/Stockholm',
      },
    };
  },
  computed: {
    ...mapState('clinicMap', ['clinicsInView']),
    ...mapState('admin', ['countryId']),
    ...mapState('user', ['user']),
  },
  mounted() {
    this.$root.$i18n.locale = 'uk';
  },
  methods: {
    ...mapActions('auth', ['logout']),
  },
};
</script>
