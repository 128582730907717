var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _c(
        "text-button",
        {
          staticClass: "w-full ",
          on: {
            click: function ($event) {
              _vm.showAvailableSlots = true
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex space-x-2 items-center" },
            [
              _c("fv-icon", { attrs: { icon: "clock" } }),
              _c("span", [_vm._v("View available FirstVet slots")]),
            ],
            1
          ),
        ]
      ),
      _vm.showAvailableSlots
        ? _c(
            "base-modal",
            {
              attrs: { show: _vm.showAvailableSlots },
              on: {
                close: function ($event) {
                  _vm.showAvailableSlots = false
                },
              },
            },
            [
              _c("available-slots", {
                style: { minHeight: "80vh" },
                attrs: {
                  "animal-types": _vm.animalTypes,
                  country: _vm.country,
                  "us-states": _vm.usStates,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }