var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.uiState !== _vm.VALIDATED_STATE,
              expression: "uiState !== VALIDATED_STATE",
            },
          ],
          staticClass: " flex flex-col space-y-4",
          attrs: { action: "post" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c("p", { staticClass: "font-black border-b pb-3" }, [
            _vm._v(" Policy validation "),
          ]),
          _c(
            "div",
            {},
            [
              _c("v-select", {
                attrs: {
                  id: "insuranceCompany",
                  value: _vm.insuranceCompany,
                  placeholder: "Select insurance company",
                  label: "name",
                  options: _vm.insuranceList,
                  loading: _vm.icLoading,
                },
                on: {
                  input: function ($event) {
                    _vm.$emit("selected-ic", $event),
                      (_vm.error = ""),
                      _vm.focusOnInputElement()
                  },
                },
              }),
            ],
            1
          ),
          _vm.insuranceCompany
            ? _c(
                "div",
                { staticClass: "flex flex-col space-y-4" },
                [
                  _c(
                    "div",
                    [
                      _c("base-input", {
                        attrs: {
                          id: "userIdNumber",
                          placeholder: "Enter social security number",
                          required: "",
                          minlength: _vm.idType === "ssn" ? 10 : 6,
                        },
                        model: {
                          value: _vm.userIdNumber,
                          callback: function ($$v) {
                            _vm.userIdNumber = $$v
                          },
                          expression: "userIdNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.error
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center space-x-3 bg-red-100 rounded p-4 border border-red-200 text-red-900",
                        },
                        [
                          _c(
                            "span",
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "exclamation-circle" },
                              }),
                            ],
                            1
                          ),
                          _c("span", [_vm._v(_vm._s(_vm.error))]),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "base-button",
                    { attrs: { color: "dark", type: "submit" } },
                    [
                      _c("span", { staticClass: "mr-2" }, [_vm._v("Check")]),
                      _c("font-awesome-icon", {
                        staticClass: "text-sm",
                        attrs: { icon: "arrow-right" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.uiState === _vm.VALIDATING_STATE
            ? _c("spinner-overlay", { attrs: { loading: true } }, [
                _c("div", { staticClass: "flex flex-col items-center" }, [
                  _c("div", { staticClass: "flex space-x-3 items-center" }, [
                    _c("p", { staticClass: "font-black" }, [
                      _vm._v(" Checking insurance & account status.. "),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.uiState === _vm.VALIDATED_STATE
        ? _c("clinic-listing-validation-result", {
            attrs: {
              "insurance-company": _vm.insuranceCompany,
              "lookup-response": _vm.validationResponse,
            },
            on: {
              reset: function ($event) {
                return _vm.resetValues()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }