var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col space-y-6" },
    [
      _c("div", { staticClass: "flex flex-col space-y-2" }, [
        _c(
          "ul",
          { staticClass: "flex flex-col space-y-2" },
          [
            _vm._l(_vm.topRatedClinics, function (clinic) {
              return _c("ClinicListingClinicRow", {
                key: clinic.id,
                attrs: {
                  clinic: clinic,
                  "insurance-company": _vm.insuranceCompany,
                },
                on: { "add-clinic": _vm.addClinic },
              })
            }),
            !_vm.topRatedClinics.length
              ? _c("li", [
                  _c("p", [
                    _vm._v("Move around or pan out to find more clinics."),
                  ]),
                ])
              : _vm._e(),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "flex justify-end" },
          [
            _vm.bottomRatedAmount > 0
              ? _c(
                  "text-button",
                  {
                    attrs: { size: "sm" },
                    on: {
                      click: function ($event) {
                        _vm.showFullList = true
                      },
                    },
                  },
                  [
                    _vm._v(" View full list "),
                    _c("span", { staticClass: "ml-1" }, [
                      _vm._v("(" + _vm._s(_vm.notSelectedClinics.length) + ")"),
                    ]),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.selectedClinics.length,
              expression: "selectedClinics.length",
            },
          ],
          staticClass: "flex flex-col",
          attrs: { id: "recommendations" },
        },
        [
          _c("p", { staticClass: "pb-3 border-b font-black" }, [
            _vm._v(" Recommendations "),
          ]),
          _c(
            "ul",
            { staticClass: "mt-2" },
            _vm._l(_vm.selectedClinics, function (clinic) {
              return _c(
                "li",
                { key: clinic.id, staticClass: "flex justify-between py-2 " },
                [
                  _c(
                    "div",
                    { staticClass: "flex space-x-2 items-center" },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "text-green-600 text-xs",
                        attrs: { icon: "check-circle" },
                      }),
                      _c("p", [_vm._v(_vm._s(clinic.name))]),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.removeClinic(clinic)
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "text-gray-600 hover:text-gray-700 ",
                        attrs: { icon: "minus" },
                      }),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          ),
          _c(
            "base-button",
            {
              staticClass: "mt-4",
              attrs: { color: "dark" },
              on: {
                click: function ($event) {
                  _vm.showSendModal = true
                },
              },
            },
            [
              _c("div", { staticClass: "inline-flex items-center space-x-2" }, [
                _c("span", [_vm._v("Send recommendations")]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm.showFullList
        ? _c(
            "base-modal",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFullList,
                  expression: "showFullList",
                },
              ],
              attrs: { id: "fullList" },
              on: {
                close: function ($event) {
                  _vm.showFullList = false
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "p-4overflow-hidden",
                  staticStyle: { height: "65vh" },
                },
                [
                  _c(
                    "div",
                    { staticClass: " flex flex-col space-y-4 pr-4" },
                    [
                      _c("base-input", {
                        attrs: {
                          type: "search",
                          name: "searchStr",
                          placholder: "Search",
                        },
                        model: {
                          value: _vm.searchStr,
                          callback: function ($$v) {
                            _vm.searchStr = $$v
                          },
                          expression: "searchStr",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex space-x-4 text-xs font-semibold px-3",
                        },
                        [
                          _c("div", { staticClass: "w-2/3" }, [
                            _vm._v(" Clinic "),
                          ]),
                          _c("div", { staticClass: "flex-1 text-left" }, [
                            _vm._v(" Price "),
                          ]),
                          _c("div", { staticClass: "flex-1 text-left" }, [
                            _vm._v(" Quality "),
                          ]),
                          _c("div", { staticClass: "flex-1 text-left" }, [
                            _vm._v(" Insurance "),
                          ]),
                          _c("div", { staticClass: "flex-1 text-left" }, [
                            _vm._v(" Rating "),
                          ]),
                          _c("div", { staticClass: "flex-1 text-left" }),
                        ]
                      ),
                      _c(
                        "ul",
                        {
                          staticClass: "flex flex-col space-y-2 overflow-auto",
                        },
                        _vm._l(_vm.searchResult, function (clinic) {
                          return _c("ClinicListingClinicRow", {
                            key: clinic.id,
                            attrs: {
                              clinic: clinic,
                              "insurance-company": _vm.insuranceCompany,
                              "show-all-ratings": true,
                            },
                            on: { "add-clinic": _vm.addClinic },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm.insuranceCompany && _vm.showSendModal
        ? _c("clinic-listing-send-form", {
            attrs: {
              show: _vm.showSendModal,
              "insurance-company": _vm.insuranceCompany,
              clinics: _vm.selectedClinics,
            },
            on: {
              close: function ($event) {
                _vm.showSendModal = false
              },
              sent: _vm.clearSelect,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }