var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "flex justify-between items-center bg-white",
      class: {
        "border rounded-lg ring-1 ring-black ring-opacity-5 px-3":
          _vm.showAddButton,
      },
    },
    [
      _c("p", { staticClass: "w-2/3 py-2" }, [
        _vm._v(" " + _vm._s(_vm.clinic.name) + " "),
      ]),
      !_vm.showAllRatings
        ? _c("div", { staticClass: "inline-flex space-x-2 items-center" }, [
            _c(
              "span",
              {
                staticClass:
                  "py-2 px-2 bg-action text-white text-sm font-black",
              },
              [_vm._v(" " + _vm._s(_vm.clinic.aggRating) + " ")]
            ),
            _vm.showAddButton
              ? _c(
                  "button",
                  { on: { click: _vm.addClinic } },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "text-gray-600 hover:text-gray-700",
                      attrs: { icon: "plus" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.showAllRatings
        ? _c(
            "div",
            {
              staticClass:
                "inline-flex flex-1 space-x-2 items-center text-center",
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "text-sm font-semibold flex-1 bg-gray-200 border-r py-2",
                },
                [_vm._v(" " + _vm._s(_vm.clinic.rating_price) + " ")]
              ),
              _c("span", { staticClass: "text-sm font-semibold flex-1" }, [
                _vm._v(" " + _vm._s(_vm.clinic.rating_quality) + " "),
              ]),
              _c("span", { staticClass: "text-sm font-semibold flex-1" }, [
                _vm._v(" " + _vm._s(_vm.clinic.currentRating.rating) + " "),
              ]),
              _c(
                "span",
                {
                  staticClass:
                    "bg-action text-white text-sm font-black flex-1  py-2",
                },
                [_vm._v(" " + _vm._s(_vm.clinic.aggRating) + " ")]
              ),
              _vm.showAddButton
                ? _c(
                    "button",
                    {
                      staticClass: "py-2 px-3",
                      on: {
                        click: function ($event) {
                          return _vm.addClinic(_vm.clinic)
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "text-gray-600 hover:text-gray-700",
                        attrs: { icon: "plus" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }