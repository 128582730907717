<template>
  <div class="w-full">
    <text-button
      class="w-full "
      @click="showAvailableSlots = true"
    >
      <div class="flex space-x-2 items-center">
        <fv-icon
          icon="clock"
        /><span>View available FirstVet slots</span>
      </div>
    </text-button>

    <base-modal
      v-if="showAvailableSlots"
      :show="showAvailableSlots"
      @close="showAvailableSlots = false"
    >
      <available-slots
        :style="{minHeight: '80vh'}"
        :animal-types="animalTypes"
        :country="country"
        :us-states="usStates"
      />
    </base-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AvailableSlots from '@/components/admin/schedule/schedule-displays/AvailableSlots';

export default {
  components: {
    AvailableSlots,
  },
  props: {
    country: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showAvailableSlots: false,
    };
  },
  computed: {
    ...mapState('animal', ['animalTypes']),
    ...mapState('country', ['usStates']),
  },
  mounted() {
    if (!this.animalTypes.length) {
      this.fetchAnimalTypes();
    }
    if (!this.usStates.length) {
      this.fetchUsStates();
    }
  },
  methods: {
    ...mapActions('animal', ['fetchAnimalTypes']),
    ...mapActions('country', ['fetchUsStates']),
  },
};
</script>

<style lang="scss" scoped>
</style>
