<template>
  <div>
    <base-modal
      class=" max-w-2xl mx-auto"
      :show="show"
      @close="$emit('close')"
    >
      <spinner-overlay
        :loading="loading"
        size="lg"
      />
      <div class="p-4 flex flex-col space-y-4">
        <div class="flex flex-col space-y-4">
          <div class="flex flex-col space-y-3">
            <div class="flex space-x-4 text-xs font-semibold">
              <div class="w-2/3">
                Clinic
              </div>
              <div class="flex-1 text-left">
                Price
              </div>
              <div class="flex-1 text-left">
                Quality
              </div>
              <div class="flex-1 text-left">
                Insurance
              </div>
              <div class="flex-1 text-left">
                Rating
              </div>
            </div>
            <ul class="flex flex-col space-y-1">
              <clinic-listing-clinic-row
                v-for="clinic in clinics"
                :key="clinic.id"
                :clinic="clinic"
                :insurance-company="insuranceCompany"
                :show-all-ratings="true"
                :show-add-button="false"
              />
            </ul>
          </div>

          <div class="block">
            <p class="font-semibold text-lg py-2 ">
              {{ insuranceCompany.name }}
            </p>
          </div>
        </div>

        <div class="flex flex-col space-y-4 py-4 border-t border-gray-200">
          <div class="flex space-x-2">
            <div class="w-1/4 flex flex-col">
              <base-label for="countryCode">
                Country code
              </base-label>
              <base-input
                id="countryCode"
                v-model="countryCode"
                :disabled="!!success"
                max="3"
                min="2"
                name="countryCode"
              />
            </div>
            <div class="flex-1 flex flex-col">
              <base-label for="phoneNumber">
                Mobile phone number
              </base-label>
              <base-input
                id="phoneNumber"
                v-model="phoneNumber"
                :disabled="!!success"
                name="phoneNumber"
                type="tel"
                autofocus
              />
            </div>
          </div>
          <div
            v-if="error || success"
            class="flex items-center space-x-3 bg-red-100 rounded p-4 border "
            :class="error ? 'border-red-200 text-red-900 bg-red-100' : 
              'border-green-300 text-green-900 bg-green-100'"
          >
            <span><font-awesome-icon :icon="error ? 'exclamation-circle' : 'info-circle'" /></span>
            <span>{{ error || success }}</span>
          </div>


          <base-button
            :disabled="disableSend || !!success"
            color="primary"
            @click="send"
          >
            Send
          </base-button>
        </div>
      </div>
    </base-modal>
  </div>
</template>

<script>
import ClinicListingClinicRow from '@/components/clinic-listing/ClinicListingClinicRow.vue';
import { ClinicListingApi } from '@/api';

export default {
  components: {
    ClinicListingClinicRow,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    insuranceCompany: {
      type: Object,
      required: true,
    },
    clinics: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      phoneNumber: '',
      countryCode: '46',
      error: '',
      loading: false,
      success: '',
    };
  },
  computed: {
    clinicIds() {
      return this.clinics.map(clinic => clinic.id);
    },
    disableSend() {
      return this.countryCode.length < 2 || this.phoneNumber.length < 8;
    },
  },
  methods: {
    async send() {
      this.error = '';
      this.loading = true;
      const { countryCode, phoneNumber, insuranceCompany } = this;
      const payload = {
        clinics: this.clinicIds,
        insuranceCompany: insuranceCompany.id,
        countryCode,
        phoneNumber,
      };
      try {
        const response = await ClinicListingApi.send(payload);
        console.log(response);
        this.loading = false;
        this.success = 'Recommendations were successfully sent!';
        setTimeout(() => {
          this.success = '';
          this.$emit('sent');
        }, 2000);
      } catch (error) {
        this.loading = false;
        this.error = error.message;
      }
    },
  },
};
</script>
