<template>
  <div class="flex flex-col">
    <div class="flex justify-between items-center border-b pb-3">
      <p class="font-black">
        Result
      </p>
      <text-button
        size="sm"
        @click=";($emit('reset'))"
      >
        <font-awesome-icon
          icon="undo"
          class="text-primary-darker hover:text-primary-darkest"
        />
      </text-button>
    </div>
    <div class="flex flex-col space-y-4 py-4">
      <p
        class="inline-flex space-x-2 items-center"
      >
        <font-awesome-icon
          class="text-xs"
          :icon="userAccount ? 'check-circle' : 'times'"
          :class="{'text-green-700' : userAccount, 'text-red-700': !userAccount}"
        />
        <span>Firstvet account found</span>
      </p>
      <p
        class="inline-flex space-x-2 items-center"
      >
        <font-awesome-icon
          :class="{'text-green-700' : policyStatus, 'text-red-700': !policyStatus}"
          :icon="policyStatus ? 'check-circle' : 'times'"
        />
        <span v-if="policyStatus">Active 
          <span class="font-semibold italic">{{ insuranceCompany.name }}</span> insurance
        </span>
        <span v-if="!policyStatus">
          Couldn't find an active 
          <span class="font-semibold italic">{{ insuranceCompany.name }}</span> insurance
        </span>
      </p>
      <p
        v-if="policyStatus && animals.length"
        class="inline-flex space-x-2 items-center"
      >
        <font-awesome-icon
          icon="paw"
        />
        <span
          v-for="animal in animals"
          :key="animal.name"
          class="font-semibold italic mr-2"
        >{{ animal.name }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: [Object, null],
      default: null,
    },
    lookupResponse: {
      type: Object,
      required: true,
    },
  },
  computed: {
    userAccount() {
      return this.lookupResponse.user.id ? this.lookupResponse.user : null;
    },
    policyStatus() {
      return this.lookupResponse.hasActivePolicy;
    },
    insuranceCompany() {
      return this.lookupResponse.insuranceCompany;
    },
    animals() {
      return this.lookupResponse.animals;
    },
  },
};
</script>
