var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col" }, [
    _c(
      "div",
      { staticClass: "flex justify-between items-center border-b pb-3" },
      [
        _c("p", { staticClass: "font-black" }, [_vm._v(" Result ")]),
        _c(
          "text-button",
          {
            attrs: { size: "sm" },
            on: {
              click: function ($event) {
                _vm.$emit("reset")
              },
            },
          },
          [
            _c("font-awesome-icon", {
              staticClass: "text-primary-darker hover:text-primary-darkest",
              attrs: { icon: "undo" },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "flex flex-col space-y-4 py-4" }, [
      _c(
        "p",
        { staticClass: "inline-flex space-x-2 items-center" },
        [
          _c("font-awesome-icon", {
            staticClass: "text-xs",
            class: {
              "text-green-700": _vm.userAccount,
              "text-red-700": !_vm.userAccount,
            },
            attrs: { icon: _vm.userAccount ? "check-circle" : "times" },
          }),
          _c("span", [_vm._v("Firstvet account found")]),
        ],
        1
      ),
      _c(
        "p",
        { staticClass: "inline-flex space-x-2 items-center" },
        [
          _c("font-awesome-icon", {
            class: {
              "text-green-700": _vm.policyStatus,
              "text-red-700": !_vm.policyStatus,
            },
            attrs: { icon: _vm.policyStatus ? "check-circle" : "times" },
          }),
          _vm.policyStatus
            ? _c("span", [
                _vm._v("Active "),
                _c("span", { staticClass: "font-semibold italic" }, [
                  _vm._v(_vm._s(_vm.insuranceCompany.name)),
                ]),
                _vm._v(" insurance "),
              ])
            : _vm._e(),
          !_vm.policyStatus
            ? _c("span", [
                _vm._v(" Couldn't find an active "),
                _c("span", { staticClass: "font-semibold italic" }, [
                  _vm._v(_vm._s(_vm.insuranceCompany.name)),
                ]),
                _vm._v(" insurance "),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.policyStatus && _vm.animals.length
        ? _c(
            "p",
            { staticClass: "inline-flex space-x-2 items-center" },
            [
              _c("font-awesome-icon", { attrs: { icon: "paw" } }),
              _vm._l(_vm.animals, function (animal) {
                return _c(
                  "span",
                  {
                    key: animal.name,
                    staticClass: "font-semibold italic mr-2",
                  },
                  [_vm._v(_vm._s(animal.name))]
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }