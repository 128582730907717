<template>
  <div class="">
    <form
      v-show="uiState !== VALIDATED_STATE"
      action="post"
      class=" flex flex-col space-y-4"
      @submit.prevent="submit"
    >
      <p class="font-black border-b pb-3">
        Policy validation
      </p>
      <div class="">
        <v-select
          id="insuranceCompany"
          :value="insuranceCompany"
          placeholder="Select insurance company"
          label="name"
          :options="insuranceList"
          :loading="icLoading"
          @input=";($emit('selected-ic', $event), error = '', focusOnInputElement())"
        />
      </div>
      <div
        v-if="insuranceCompany"
        class="flex flex-col space-y-4"
      >
        <!-- <div class="flex space-x-3">
          <div class="flex space-x-1 items-center ">
            <label for="ssnInput">
              <span class="text-gray-800 text-sm">SSN</span>
            </label>
            <input
              id="ssnInput"
              v-model="idType"
              type="radio"
              name="idType"
              value="ssn"
            >
          </div>
          <div class="flex space-x-1 items-center ">
            <label for="icNumberInput">
              <span class="text-gray-800 text-sm">Insurance number</span>
            </label>
            <input
              id="icNumberInput"
              v-model="idType"
              type="radio"
              name="idType"
              value="icNumber"
            >
          </div>
        </div> -->
        <div>
          <base-input
            id="userIdNumber"
            v-model="userIdNumber"
            placeholder="Enter social security number"
            required
            :minlength="idType === 'ssn' ? 10 : 6"
          />
        </div>
        <div
          v-if="error"
          class="flex items-center space-x-3 bg-red-100 rounded p-4 border border-red-200 text-red-900"
        >
          <span><font-awesome-icon icon="exclamation-circle" /></span>
          <span>{{ error }}</span>
        </div>
        <base-button
          color="dark"
          type="submit"
        >
          <span class="mr-2">Check</span>
          <font-awesome-icon
            class="text-sm"
            icon="arrow-right"
          />
        </base-button>
      </div>

      <spinner-overlay
        v-if="uiState === VALIDATING_STATE"
        :loading="true"
      >
        <div class="flex flex-col items-center">
          <div class="flex space-x-3 items-center">
            <p class="font-black">
              Checking insurance & account status..
            </p>
          </div>
        </div>
      </spinner-overlay>
    </form>
    <clinic-listing-validation-result
      v-if="uiState === VALIDATED_STATE"
      :insurance-company="insuranceCompany"
      :lookup-response="validationResponse"
      @reset="resetValues()"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ClinicListingValidationResult from '@/components/clinic-listing/ClinicListingValidationResult';
import { ClinicListingApi } from '@/api';

const VALIDATING_STATE = 'validating';
const IDLE_STATE = 'idle';
const VALIDATED_STATE = 'validationFinished';

export default {
  components: {
    ClinicListingValidationResult,
  },
  props: {
    insuranceCompany: {
      type: [Object, null],
      default: null,
    },

    countryId: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      icLoading: false,
      userIdNumber: '',
      idType: 'ssn',
      uiState: IDLE_STATE,
      error: '',
      validationResponse: null,
      VALIDATING_STATE,
      VALIDATED_STATE,
    };
  },
  computed: {
    ...mapState('insurance', ['insuranceList']),
  },
  async mounted() {
    this.icLoading = true;
    await this.fetchCompaniesByCountryId({
      countryId: this.countryId,
      params: { clinicListing: 1 },
    });
    this.icLoading = false;
  },

  methods: {
    ...mapActions('insurance', ['fetchCompaniesByCountryId']),

    async submit() {
      this.error = '';
      this.uiState = VALIDATING_STATE;
      const { insuranceCompany, userIdNumber, idType } = this;
      const payload = {
        insuranceCompanyId: insuranceCompany.id,
        userIdNumber,
        idType,
      };
      try {
        const response = await ClinicListingApi.validate(payload);
        this.uiState = VALIDATED_STATE;
        this.validationResponse = response;
      } catch (error) {
        this.uiState = IDLE_STATE;
        this.error = error.message;
      }
    },

    resetValues() {
      this.$emit('selected-ic', null);
      this.userIdNumber = '';
      this.userIdInput = 'ssn';
      this.validationResponse = null;
      this.uiState = IDLE_STATE;
    },

    focusOnInputElement() {
      this.$nextTick(() => {
        const userIdInput = document.getElementById('userIdNumber');
        if (userIdInput && userIdInput.children[0]) {
          userIdInput.children[0].focus();
        }
      });
    },
  },
};
</script>
