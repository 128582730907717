<template>
  <li
    class="flex justify-between items-center bg-white"
    :class="{'border rounded-lg ring-1 ring-black ring-opacity-5 px-3' : showAddButton}"
  >
    <p class="w-2/3 py-2">
      {{ clinic.name }}
    </p>

    <div
      v-if="!showAllRatings"
      class="inline-flex space-x-2 items-center"
    >
      <span class="py-2 px-2 bg-action text-white text-sm font-black">
        {{ clinic.aggRating }}
      </span>
      <button
        v-if="showAddButton"
        @click="addClinic"
      >
        <font-awesome-icon
          icon="plus"
          class="text-gray-600 hover:text-gray-700"
        />
      </button>
    </div>


    <div
      v-if="showAllRatings"
      class="inline-flex flex-1 space-x-2 items-center text-center"
    >
      <span class="text-sm font-semibold flex-1 bg-gray-200 border-r py-2">
        {{ clinic.rating_price }}
      </span>
      <span class="text-sm font-semibold flex-1">
        {{ clinic.rating_quality }}
      </span>
      <span class="text-sm font-semibold flex-1">
        {{ clinic.currentRating.rating }}
      </span>
      <span class="bg-action text-white text-sm font-black flex-1  py-2">
        {{ clinic.aggRating }}
      </span>
      <button
        v-if="showAddButton"
        class="py-2 px-3"
        @click="addClinic(clinic)"
      >
        <font-awesome-icon
          icon="plus"
          class="text-gray-600 hover:text-gray-700"
        />
      </button>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    clinic: {
      type: Object,
      required: true,
    },
    showAllRatings: {
      type: Boolean,
      default: false,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    insuranceCompany: {
      type: Object,
      required: true,
    },
  },
  methods: {
    addClinic() {
      this.$emit('add-clinic', this.clinic);
    },
  },
};
</script>
